import { SeatMap } from "./camera";
import { CropData, Coords } from "./cropper";
import { DocumentInput } from "./general";

export interface MomentInput {
  team1Score?: number;
  team2Score?: number;
  text?: string;
  period?: string;
  momentStart: number;
  stadium: string;
  camera: string;
  momentoKeys: string[];
  sponsorOverlay?: string;
  sponsorOverlayImage?: string;
  momentTime?: string;
  momentPeriodType?: string;
  momentPeriodText?: string;
  isMOTG?: boolean;
  isUnpaid?: boolean;
  hideScoreboard?: boolean;
}

export type MomentItem = Omit<MomentInput, "momentoKeys"> & {
  momentoLinks: string[];
};

export interface MomentResponse extends DocumentInput, MomentItem {}

export interface MomentResponseWithSize extends Omit<CropData, "oX" | "oY"> {
  moments: Array<DocumentInput & MomentItem>;
  defaultZoomWidth: number;
  defaultZoomHeight: number;
  offsetTop: number;
  relativeCoords: Coords;
  isPaid?: boolean;
  momentsTotalCount?: number;
  momentosTotalCount?: number;
}

export interface GetGameMomentsRequests {
  stadium: string;
  from: number;
  to?: number;
  mappedSeat: MomentSeat;
  resizeWidth?: number;
  compressRate?: number;
  gameId?: string;
  email?: string;
  fromMoment?: number;
  toMoment?: number;
}

export type MomentSeat = Omit<SeatMap, "x" | "y" | "key">;

export interface MomentoTrigger {
  momentoKey: string;
}

export enum FileTypes {
  IMAGE,
  GIF,
}

export enum MomentStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  ARCHIVED = "archived",
}

export interface OperatorMoment extends DocumentInput {
  status: MomentStatus;
  momentStart: number;
  team1Score?: number;
  team2Score?: number;
  text?: string;
  momentoCount: number;
  camera: string;
  sponsorOverlay?: string;
  momentTime?: string;
  momentPeriodType?: string;
  momentPeriodText?: string;
  isMOTG?: boolean;
  hideScoreboard?: boolean;
}

export type GetOperatorMomentRequest = Omit<
  GetGameMomentsRequests,
  "mappedSeat"
>;

export interface UpdateMomentRequest {
  status?: MomentStatus;
  team1Score?: number;
  team2Score?: number;
  text?: string;
  sponsorOverlay?: string;
  momentTime?: string;
  momentPeriodType?: string;
  momentPeriodText?: string;
  isMOTG?: boolean;
  hideScoreboard?: boolean;
}

export interface PreviewSeat {
  seat: string;
  row: string;
  sectionName: string;
}

export interface PreviewSeats {
  seats: PreviewSeat[];
  momentIds?: string[];
}

export interface MomentLinks {
  momentOLinks: string[];
}

export interface MomentLinksWithFullSize extends MomentLinks {
  fullSizeLinks: string[];
}

export interface PreviewSeatMomentLinks extends MomentLinks {
  prSeat: PreviewSeat;
  width: number;
  height: number;
  defaultZoomWidth: number;
  defaultZoomHeight: number;
  offsetTop: number;
  relativeCoords: Coords;
  sponsorOverlayImage?: string;
  resizeWidth: number;
}

export interface GeneralMomentInput {
  startTime: number;
  moments: string[];
  stadium: string;
}

export interface GeneralMomentClient
  extends DocumentInput,
    Omit<GeneralMomentInput, "moments"> {
  moments: OperatorMoment[];
  useInAnalyze?: boolean;
}

export interface CroppedVideoDownloadData {
  x: number;
  y: number;
  width: number;
  height: number;
  link: string;
  name: string;
}

export enum CroppedVideoFormat {
  MP4,
  GIF,
}

export interface GetCroppedVideoRequest {
  framesPerSecond: number;
  sponsorOverlayUrl: string;
  momentArray: CroppedVideoDownloadData[];
  outputFormat?: CroppedVideoFormat;

  saveFramesToTmp?: boolean;
  writeVideoToFile?: boolean;
  saveVideoToTmp?: boolean;
  printFileSystemStats?: boolean;
  retrieveFramesInParallel?: boolean;
  retrieveFramesViaWeb?: boolean;
}

export enum MomentsAnalysisType {
  SECTIONS,
  SEAT_PAIRINGS,
}

export interface AnalyzeMomentsRequest {
  moments: (string[])|({momentId: string, cameraId: string}[]);
  analysisType?: MomentsAnalysisType;
}

export interface PurchaseLinkImageInput {
  url: string;
  email: string;
  width: number;
  height: number;
  isSpecialSection?: boolean;
  section?: string;
  row?: string;
  seat?: string;
  gameId?: string;
  momentId?: string;
  momentoIndex?: number;
  caption?: string;
  created?: Date;
}

export interface PrintOrderNumberInput {
  gameId: string;
  number?: string;
}
