import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import {
  BasicResponse, OperatorMoment, GetOperatorMomentRequest,
  PreviewSeat, UpdateMomentRequest, MomentLinks, PreviewSeatMomentLinks,
  PreviewSeats, UpdateUserUniqueUrl, UniqueUser, AnalyzeMomentsRequest, PurchaseLinkImageInput,
} from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

import { NotificationServiceInstance } from "../../services/notification";

export const getOperatorMomentListRequest = async (
  request: GetOperatorMomentRequest,
): Promise<OperatorMoment[]> => {
  try {
    const response = await instance.post<OperatorMoment[]>(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getOperatorMomentRequest = async (
  momentId: string,
): Promise<OperatorMoment> => {
  try {
    const response = await instance.get<OperatorMoment>(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${momentId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getMomentMomentoListRequest = async (
  momentId: string,
): Promise<MomentLinks> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.LINK}/${momentId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const deleteMomentLinkRequest = async (
  momentId: string,
  link: string,
): Promise<MomentLinks> => {
  try {
    const response = await instance.patch(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.LINK}/${momentId}`, {
      link,
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getMomentSeatPreviewRequest = async (
  request: PreviewSeat|null,
  momentId: string,
): Promise<PreviewSeatMomentLinks> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.PREVIEW}/${momentId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const getPreparedImagesRequest = async (
  request: PreviewSeats|null,
): Promise<PreviewSeatMomentLinks[]> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.PREVIEW}/${PATH.PREPARE_IMAGES}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const uploadMOTGImage = async (
  request: FormData,
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.UPLOAD_MOTG_IMAGE}`, request);

    return response?.data?.imageShortUrl || "";
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export interface UploadLargeS3ImageResponse {
  success: boolean;
  imageKey: string;
  uploadKey: string;
  uploadURL: string;
  imageShortUrl: string;
}

export const prepareUploadLargeMOTGImage = async (
  fileName: string,
  fileMimeType: string,
  s3Folder?: string,
): Promise<UploadLargeS3ImageResponse> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.UPLOAD_LARGE_MOTG_IMAGE}`, {
      fileName,
      fileMimeType,
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const updateUsersUniqueUrls = async (
  updates: UpdateUserUniqueUrl[],
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.USER}/${PATH.UPDATE_USERS_UNIQUE_URLS}`, { updates });

    return response?.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const clearUsersUniqueUrls = async (
  ids: string[],
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.USER}/${PATH.CLEAR_USERS_UNIQUE_URLS}`, { ids });

    return response?.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const updateUsersSeatUrls = async (
  updates: UpdateUserUniqueUrl[],
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.USER}/${PATH.UPDATE_USERS_SEAT_URLS}`, { updates });

    return response?.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const createNewUsers = async (
  users: UniqueUser[],
): Promise<{ success?: boolean, responseText?: string}> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.USER}/${PATH.CREATE_NEW_USERS}`, { users });

    return response?.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const getMomentSeatPreviewAllRequest = async (
  request: PreviewSeat|null,
  momentId: string,
): Promise<PreviewSeatMomentLinks> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.PREVIEW}/${PATH.ALL}/${momentId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;

    throw new Error(response.data.error);
  }
};

export const updateMomentRequest = async (
  request: UpdateMomentRequest,
  momentId: string,
): Promise<boolean> => {
  try {
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${momentId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const analyzeMomentsRequest = async (
  request: AnalyzeMomentsRequest,
): Promise<{ success: boolean,
             totalNumberOfMoments: number,
             totalNumberOfImages: number}> => {
  try {
    const analyzeResponse = await instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.ANALYZE}`, request);
    /*
    if ("sectionLinksFileKeys" in analyzeResponse.data) {
      const promises = [];

      // prior to requesting any seat pairings, ensure that the cropped images for the parent
      // sections are cached to S3
      if ("parentSectionLinksFileKeys" in analyzeResponse.data) {
        for (let i = 0; i < analyzeResponse.data.parentSectionLinksFileKeys.length; i += 1) {
          const sectionImageGenRequest = {
            sectionLinksWithTargetKey: analyzeResponse.data.parentSectionLinksFileKeys[i],
          };
          promises.push(instance.post(`${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT
          }/${PATH.TRIGGER_SECTION_IMAGE_GEN}`, sectionImageGenRequest));
        }

        await Promise.all(promises);
      }

      // the condition below is a non-so-fancy way of commenting out some code in case we ever want
      // to use it again
      NotificationServiceInstance
      .showInfoNotification(`Preliminary Analysis complete; queuing ${
      analyzeResponse.data.totalNumberOfImages} images for retrieval...`);
      const processRequestsInParallel = ("processRequestsInParallel"
       in analyzeResponse.data) ? analyzeResponse.data.processRequestsInParallel : false;
      if (processRequestsInParallel) {
        for (let i = 0; i < analyzeResponse.data.sectionLinksFileKeys.length; i += 1) {
          const sectionImageGenRequest = {
            sectionLinksWithTargetKey: analyzeResponse.data.sectionLinksFileKeys[i],
          };
          promises.push(instance.post(`${PATH.API}/${PATH.OPERATOR
          }/${PATH.MOMENT}/${PATH.TRIGGER_SECTION_IMAGE_GEN}`, sectionImageGenRequest));
        }

        await Promise.all(promises);
      } else {
        for (let i = 0; i < analyzeResponse.data.sectionLinksFileKeys.length; i += 1) {
          const sectionImageGenRequest = {
            sectionLinksWithTargetKey: analyzeResponse.data.sectionLinksFileKeys[i],
          };

          // eslint-disable-next-line no-await-in-loop
          const sectionImageGenResponse
           = await instance.post(
           `${PATH.API}/${PATH.OPERATOR}/${PATH.MOMENT}/${PATH.TRIGGER_SECTION_IMAGE_GEN}`,
            sectionImageGenRequest);
          NotificationServiceInstance.showInfoNotification
          (`Queued ${i + 1}${(i === 0) ? "st" : (i === 1) ? "nd" : (i === 2) ? "rd" : "th"
          } block of ${sectionImageGenResponse.data.numImagesRequested} images for retrieval...`);
        }
      }

      // remove the sectionLinksFileKeys from the object before returning to the client
      delete analyzeResponse.data.sectionLinksFileKeys;
    }
    */

    return analyzeResponse.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addPurchaseLink = async (
  request: PurchaseLinkImageInput,
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.MOMENT}/${PATH.SAVE_PURCHASE_LINK}`, request);

    return response?.data?._id || "";
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
